
import { XIcon } from "vue-feather-icons";
export default {
    name: "DialogModal",
    components: { XIcon },
    props: {
        centered: {
            default: false
        },
        size: {
            default: ''
        },
        url: {
            default: false
        },
        strict: {
            default: false,
        },
        topFix: {
            default: false,
        },
        allowClose: {
            default: true
        },
        // when we just want both the header and footer to be hidden
        contentOnly: {
            default: false,
            type: Boolean
        },
        // when we just want the header to be hidden
        noHeader: {
            default: false,
            type: Boolean
        },
        fullbox: {
            default: false
        },
        fullScreenOnResponsive: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({ show: false }),
    watch: {
        show(val) {
            this.$store.commit('store/setDialogIsOpen', val)
        },
    },
    methods: {
        open() {
            this.$store.commit('store/setDialogIsOpen', true)
            /**
             * We are executing the following block of code inside
             * a 250ms timeout. This puts it under a separate event 
             * queue and will result in a arbitrary delay which would
             * be enough for the style changes required to render the
             * dialog to be applied. 
             * Without this we would see a flickering of the navigation
             * bar when we open the dialog.
             */
            setTimeout(() => {
                this.show = true;
                if (!this.fullbox) document.body.classList.add("scroll--off-dialog");
            }, 250);
        },
        close() {
            document.body.classList.remove("scroll--off-dialog");
            this.$emit("close", "modalClose");
            this.show = false;
        },
        cancel() {
            document.body.classList.remove("scroll--off-dialog");
            this.$emit("cancel");
            this.show = false;
        },
    },
};
